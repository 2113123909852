import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import UnderDevelopment from 'images/underdevelopment.jpg';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      height: '100%',
      backgroundColor: 'transparent',
    },
    media: {
      borderRadius: theme.radius,
    },
    padding: {
      padding: theme.spacing(1),
    },
  }),
);

export default function PageUnderDevelopment() {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.padding}>
        <Typography variant="h5">
          Stay tuned, we're developing awesome things!
        </Typography>
      </div>
      <img
        src={UnderDevelopment}
        alt="under developement"
        width={'50%'}
        height={'80%'}
        className={classes.media}
      />
    </Card>
  );
}
