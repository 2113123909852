import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { colors, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.radius,
    },
    cancelButton: {
      color: theme.palette.primary.main,
    },
  }),
);

interface IProps {
  cancelText?: string;
  confirmText?: string;
  isVisible: boolean;
  handleClose: () => void;
  title: string;
  description?: string;
  handleConfirm: () => void;
}

const ConfirmationDialog = ({
  cancelText,
  confirmText,
  isVisible,
  handleClose,
  title,
  description,
  handleConfirm,
}: IProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.root }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: colors.pink[500] }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            {cancelText || 'Cancel'}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmText || 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
