import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  TextField,
  RadioGroup,
  CheckboxWithLabel,
  Select,
} from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';

import {
  Divider,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  FormControlLabel,
  Radio,
  colors,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    alertRoot: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      paddingBottom: theme.spacing(2),
    },
    cardRoot: {
      //   maxWidth: 345,
      borderRadius: theme.radius,
    },
    paperCalss: {
      borderRadius: theme.radius,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    formControl: {
      display: 'flex',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    signButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    button: {
      display: 'flex',
      textTransform: 'none',
    },
    textTran: {
      textTransform: 'none',
    },
    labelColor: {},
    input1: {
      height: 15,
    },
    divCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nameDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    cardAction: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    cardRight: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    chip: {
      color: colors.amber[900],
      backgroundColor: colors.amber[50],
    },
    dropDown: {
      height: '35px',
    },
  }),
);

export default function PlaceOrder() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader title="Order details" />
            <Divider />
            <Formik
              initialValues={{
                orderType: '10',
                hcpAccountNo: 'HCP1234567',
                employeeName: '',
                accountName: 'John Daniel',
                sendCopy: true,
                selectedProduct: '10',
                formDate: moment().format('L'),
                signedForm: 'Yes',
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}
              enableReinitialize
            >
              {({
                errors,
                touched,
                submitForm,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form noValidate>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="formDate"
                          required
                          className={classes.labelColor}
                        >
                          Date
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="formDate"
                            InputProps={{
                              id: 'formDate',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="employeeName"
                          required
                          className={classes.labelColor}
                        >
                          UroGen Account Manager
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="employeeName"
                            InputProps={{
                              id: 'employeeName',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="orderType"
                          required
                          className={classes.labelColor}
                        >
                          Select Order Type
                        </InputLabel>
                        <FormControl className={clsx(classes.formControl)}>
                          <Field
                            component={Select}
                            name="orderType"
                            inputProps={{
                              id: 'orderType',
                            }}
                            className={classes.dropDown}
                            variant="outlined"
                          >
                            <MenuItem value={10}>Standard Order</MenuItem>
                            <MenuItem value={20}>
                              Promotional Program Order
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="hcpAccountNo"
                          required
                          className={classes.labelColor}
                        >
                          Customer Account No
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="hcpAccountNo"
                            InputProps={{
                              id: 'hcpAccountNo',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder="Existing Accounts Only"
                            required
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="accountName"
                          required
                          className={classes.labelColor}
                        >
                          Account Name
                        </InputLabel>
                        <FormControl
                          className={classes.formControl}
                          margin="dense"
                        >
                          <Field
                            component={TextField}
                            name="accountName"
                            InputProps={{
                              id: 'accountName',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="selectedProduct"
                          required
                          className={classes.labelColor}
                        >
                          Select Product
                        </InputLabel>
                        <FormControl className={clsx(classes.formControl)}>
                          <Field
                            component={Select}
                            name="selectedProduct"
                            inputProps={{
                              id: 'selectedProduct',
                            }}
                            className={classes.dropDown}
                            variant="outlined"
                          >
                            <MenuItem value={10}>Conexacyn</MenuItem>
                            <MenuItem value={20}>Conexanol</MenuItem>
                            <MenuItem value={30}>Convicyn</MenuItem>
                            <MenuItem value={40}>Congra-D</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="signedForm"
                          className={classes.labelColor}
                        >
                          Do you have a signed order form?
                        </InputLabel>
                        <Field component={RadioGroup} name="signedForm">
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Yes"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="No"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="sendCopy"
                            color="primary"
                            Label={{ label: 'Email me a copy of my order' }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* <Divider /> */}
                  <CardActions className={classes.cardAction}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        // console.log('Save for later');
                      }}
                      className={classes.button}
                    >
                      Save for later
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        // console.log('Submit');
                      }}
                      className={classes.button}
                    >
                      Add to cart
                    </Button>
                  </CardActions>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
