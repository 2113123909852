import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, useTheme, Typography, colors } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(theme => ({
  avatar: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
}));

interface IAvatarIconTitleProps {
  title: string;
  color: string;
  icon: IconDefinition;
  bgColor: string;
  noteText?: string;
  status?: string;
}

const AvatarIconTitle = (props: IAvatarIconTitleProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { title, color, icon, bgColor, noteText, status } = props;

  return (
    <div className={classes.avatar}>
      <Avatar
        style={{
          color: color || theme.palette.primary.main,
          backgroundColor: bgColor || theme.palette.primary.light,
        }}
      >
        <FontAwesomeIcon
          size="sm"
          icon={icon}
          color={color || theme.palette.primary.main}
        />
      </Avatar>
      <div>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        {noteText ? (
          <Typography variant="caption" className={classes.title}>
            Note: Table data filtered by{' '}
            <Typography variant="caption" style={{ color: colors.blue[800] }}>
              {noteText}
            </Typography>
          </Typography>
        ) : null}
        {status ? <span>{status}</span> : null}
      </div>
    </div>
  );
};

export default AvatarIconTitle;
