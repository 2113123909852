import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { TextField, RadioGroup, CheckboxWithLabel } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import {
  Divider,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  FormControlLabel,
  Radio,
  Chip,
  colors,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    alertRoot: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      paddingBottom: theme.spacing(2),
    },
    cardRoot: {
      //   maxWidth: 345,
      borderRadius: theme.radius,
    },
    paperCalss: {
      borderRadius: theme.radius,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    formControl: {
      display: 'flex',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    signButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    button: {
      display: 'flex',
      textTransform: 'none',
    },
    textTran: {
      textTransform: 'none',
    },
    labelColor: {},
    input1: {
      height: 15,
    },
    divCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nameDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    cardAction: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    cardRight: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    chip: {
      color: colors.amber[900],
      backgroundColor: colors.amber[50],
    },
  }),
);

export default function UserManagement() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.alertRoot}>
        <Alert severity="info" style={{ borderRadius: 10 }}>
          Your account is not active yet, please finish the setup to activate!
        </Alert>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader
              title="Account information"
              action={
                <div className={classes.cardRight}>
                  <Typography variant="subtitle1">Account Status</Typography>
                  <Chip
                    size="small"
                    className={classes.chip}
                    label="In Process"
                  />
                </div>
              }
            />
            <Divider />
            <Formik
              initialValues={{
                email: '',
                mainPOCFirstName: '',
                mainPOCLastName: '',
                phonenumber: '',
                createpassword: '',
                confirmpassword: '',
                sendCopy: '',
                formDate: moment().format('L'),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // const data = JSON.stringify(values, null, 2);
                // const parseData = JSON.parse(data);
                // console.log(parseData);
              }}
              enableReinitialize
            >
              {({
                errors,
                touched,
                submitForm,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form noValidate>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="formDate"
                          required
                          className={classes.labelColor}
                        >
                          Date
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="formDate"
                            InputProps={{
                              id: 'formDate',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="employeeName"
                          className={classes.labelColor}
                        >
                          UroGen Account Manager
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="employeeName"
                            InputProps={{
                              id: 'employeeName',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="accountName"
                          required
                          className={classes.labelColor}
                        >
                          Account Name
                        </InputLabel>
                        <div className={classes.nameDiv}>
                          <FormControl
                            className={classes.formControl}
                            style={{ paddingRight: 5 }}
                          >
                            <Field
                              component={TextField}
                              name="firstName"
                              InputProps={{
                                id: 'firstName',
                                classes: { input: classes.input1 },
                              }}
                              variant="outlined"
                              size="small"
                              placeholder="First name"
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Field
                              component={TextField}
                              name="lastName"
                              InputProps={{
                                id: 'lastName',
                                classes: { input: classes.input1 },
                              }}
                              variant="outlined"
                              size="small"
                              placeholder="Last name"
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="attention"
                          className={classes.labelColor}
                        >
                          Attention
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="attention"
                            InputProps={{
                              id: 'attention',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="phoneNumber"
                          required
                          className={classes.labelColor}
                        >
                          Phone number
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="phoneNumber"
                            InputProps={{
                              id: 'phoneNumber',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder="Provide valid US phone number"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="email"
                          required
                          className={classes.labelColor}
                        >
                          Email
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="email"
                            InputProps={{
                              id: 'email',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder="Provide valid email address"
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="streetAddress1"
                          className={classes.labelColor}
                          required
                        >
                          Ship To Address 1 - Street
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="streetAddress1"
                            InputProps={{
                              id: 'streetAddress1',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <InputLabel
                          htmlFor="streetAddress2"
                          className={classes.labelColor}
                        >
                          Ship To Address 2 - Street
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="streetAddress2"
                            InputProps={{
                              id: 'streetAddress2',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="shipToCity"
                          className={classes.labelColor}
                          required
                        >
                          Ship To City
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="shipToCity"
                            InputProps={{
                              id: 'shipToCity',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="shipToState"
                          className={classes.labelColor}
                          required
                        >
                          Ship To State
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="shipToState"
                            InputProps={{
                              id: 'shipToState',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="shipToZipcode"
                          className={classes.labelColor}
                          required
                        >
                          Ship To Zip Code
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="shipToZipcode"
                            InputProps={{
                              id: 'shipToZipcode',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="apContactPhone"
                          className={classes.labelColor}
                          required
                        >
                          AP Contact Phone
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="apContactPhone"
                            InputProps={{
                              id: 'apContactPhone',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="apContactEmail"
                          className={classes.labelColor}
                          required
                        >
                          AP Contact Phone
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="apContactEmail"
                            InputProps={{
                              id: 'apContactEmail',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <InputLabel
                          htmlFor="licenseNumber"
                          className={classes.labelColor}
                          required
                        >
                          Clinic/ Physician License Number
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={TextField}
                            name="licenseNumber"
                            InputProps={{
                              id: 'licenseNumber',
                              classes: { input: classes.input1 },
                            }}
                            variant="outlined"
                            size="small"
                            placeholder=""
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="billingSameAsShipping"
                          className={classes.labelColor}
                          required
                        >
                          Bill to address same as Ship to address?
                        </InputLabel>
                        <Field
                          component={RadioGroup}
                          name="billingSameAsShipping"
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Yes"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="No"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="paymentTerms"
                          className={classes.labelColor}
                        >
                          Preferred Payment Terms
                        </InputLabel>
                        <Field component={RadioGroup} name="paymentTerms">
                          <FormControlLabel
                            value="CC"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Prepay Account (Credit Card)"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="NET30"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Payment Terms (Net 30)"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="shippingMethod"
                          className={classes.labelColor}
                        >
                          Preferred Shipping Method
                        </InputLabel>
                        <Field component={RadioGroup} name="shippingMethod">
                          <FormControlLabel
                            value="ground"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Ground"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="Nextday"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Next Day Air (Excludes Cartridges)"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="taxExempt"
                          className={classes.labelColor}
                        >
                          Tax Exempt
                        </InputLabel>
                        <Field component={RadioGroup} name="taxExempt">
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="Yes (Tax Exempt Form is Required)"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio disabled={isSubmitting} color="primary" />
                            }
                            label="No"
                            disabled={isSubmitting}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel
                          htmlFor="document"
                          className={classes.labelColor}
                          required
                        >
                          Attach Supporting Documentation
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={DropzoneArea}
                            name="document"
                            clearOnUnmount
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="sendCopy"
                            color="primary"
                            Label={{ label: 'Email me a copy of my responses' }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* <Divider /> */}
                  <CardActions disableSpacing className={classes.cardAction}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </CardActions>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
