import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Divider, makeStyles } from '@material-ui/core';
import { DialogTitleProps, styles } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  chartView: {
    width: '70%',
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 'auto',
  },
}));

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

interface IModalDialogProps {
  title: string;
  children: React.ReactNode;
  handleClose: () => void;
  isOpen: boolean;
}

const ModalDialog = (props: IModalDialogProps) => {
  const { children, title, handleClose, isOpen } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog onClose={handleClose} open={isOpen} fullScreen>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.chartView}>{children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalDialog;
