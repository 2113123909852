export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const STORAGE_KEY = '@eaadee1a53e7433c866c6615b47eb613';

export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';
export const ERROR = 'error';

export const UNAUTH_ERROR = 'Session timed out, please login again';
export const NOT_FOUND = 'The requested resource could not be found!';
export const REQUIRED_ERROR = 'Some of the required fields are empty.';
export const INTERNAL_ERROR = 'Message from server: Internal server error';
export const GENERAL_ERROR =
  'Something just went wrong: this could be iternal server error or network issue';
export const REPORT_PERMISSION_ERROR =
  'You dont have permission to access the report';

export const VEEVA_EMAIL_VERIFICATION_URL =
  'veeva-991024dk-9919-fk3j-ali2-389b165a-36d4-9999-9e51-80a5ba304d65-fa06-4fdb-8ed6-99kkksepos21-3fd50912-5458-4ea4-80a4-2e7f509cc9ab';
export const CONSENT_FORM_URL =
  'consentform-112c0a8a-0fe2-4ad4-a054-a04de16bf4ff';
export const CONSENT_CAPTURE_FORM_URL =
  'consentcaptureform-084b79f6-45a2-4b7d-a18c-f0e05a75b7d2';
export const MAILER_SUCCESS_URL =
  'mailer-success-163386c4-18f2-11eb-adc1-0242ac120002-8cf20c4b-733f-4b3f-b7d3-524cd7c65477';
export const ACCESS_DENIED_URL =
  'access-denied-8853f55c-4c9f-4d18-84f1-f05934440098-25313865-2c24-41cc-8359-4216e2e84e82-564e33ee-4355-41f9-a26b-3aed20ffad0c-9a3a0ec1-b6e9-4949-90ea-d65692ba480d-0db0ddd2-3172';
export const SUCCESS_URL =
  'success-8498318c-4336-4bcf-9b1c-389b165a-36d4-4058-9e51-80a5ba304d65-fa06-4fdb-8ed6-7e968a5d2932-3fd50912-5458-4ea4-80a4-2e7f509cc9ab';

export const LOGIN_URL =
  'urogen-preferencehub-login-b83c359a-f4eb-407e-870f-b250f6e55952';

export const ADMIN_HOME =
  'urogen-preferencehub-admin-d6cbfdae-4163-453c-b030-b1d4dc10f189';

export enum InputSource {
  ODS = 'ODSD',
  PHUB = 'PHUB',
  SFMC = 'SFMC',
  VEEVA = 'VEEVA',
}

export enum PreferenceType {
  BRANDED = 'BRAN',
  UN_BRANDED = 'UNBR',
  COMMUNICATION = 'COMM',
  ACTIVITY = 'ACTI',
}
