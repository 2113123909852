import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { Chip, colors, Grid, Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldCheck,
  faChartLine,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

// import OrderStepper from './OrderStepper';
import HcpConsentHistory from './HcpConsentHistory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.radius,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    iconFont: { fontSize: '20px' },

    avatar: {
      backgroundColor: colors.red[500],
    },
    blue: { backgroundColor: colors.blue[500] },
    pink: { backgroundColor: colors.pink[500] },
    amber: { backgroundColor: colors.amber.A700 },
    green: { backgroundColor: colors.green.A700 },
    cardRight: {
      display: 'flex',
    },
    chip: {
      color: colors.teal[900],
      backgroundColor: colors.teal[50],
      height: 20,
    },
  }),
);

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.green}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={classes.iconFont}
                  />
                </Avatar>
              }
              title={
                <Typography variant="subtitle2">
                  Total # Subs (30days)
                </Typography>
              }
              subheader="231"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.amber}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={classes.iconFont}
                  />
                </Avatar>
              }
              title={
                <Typography variant="subtitle2">
                  Total # UnSubs (30days)
                </Typography>
              }
              subheader="240"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.pink}>
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className={classes.iconFont}
                  />
                </Avatar>
              }
              title={<Typography variant="subtitle2">Tile 3</Typography>}
              subheader="Todo"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.blue}>
                  <FontAwesomeIcon
                    icon={faShieldCheck}
                    className={classes.iconFont}
                  />
                </Avatar>
              }
              title={
                <Typography variant="subtitle2">Account Status</Typography>
              }
              subheader={
                <div className={classes.cardRight}>
                  <Chip size="small" className={classes.chip} label="Active" />
                </div>
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HcpConsentHistory />
        </Grid>
      </Grid>
    </div>
  );
}
