import React from 'react';
import Lo from 'lodash';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { Link, Chip, colors, useTheme, makeStyles } from '@material-ui/core';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import AvatarIconTitle from 'app/components/UIComponents/AvatarIconTitle';

import { OrderHistorySample } from '../constants';
import { IOrderHistorySample } from '../types';

const useStyles = makeStyles(() => ({
  toolBarDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chipDiv: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 100,
  },
}));

const HcpConsentHistory = () => {
  const theme = useTheme();
  const classes = useStyles();

  const renderCellGroup = (value: any) => {
    const code = Lo.filter(OrderHistorySample, { orderStaus: value });
    const bgColor =
      code.length > 0 ? code[0].chipBgColor : colors.blueGrey[300];
    const color = code.length > 0 ? code[0].chipColor : colors.blueGrey[300];
    return <Chip label={value} style={{ backgroundColor: bgColor, color }} />;
  };

  const renderCell = (data: string, bgColor: string, color: string) => (
    <div className={classes.chipDiv}>
      <Chip
        label={data}
        style={{ backgroundColor: bgColor, width: '100%', color }}
      />
    </div>
  );

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const columns = [
    {
      title: 'Order number',
      field: 'orderNumber',
      grouping: false,
    },
    {
      title: 'Product',
      field: 'product',
      grouping: true,
    },
    {
      title: 'Order Placed',
      field: 'orderPlaced',
      type: 'date',
      dateSetting: {
        local: 'L',
      },
      grouping: false,
    },
    {
      title: 'Order Delivered',
      field: 'orderDelivered',
      type: 'date',
      dateSetting: {
        local: 'L',
      },
      grouping: false,
    },
    {
      title: 'Status',
      field: 'orderStaus',
      grouping: true,
      render: (value, renderType, ...args) => {
        if (renderType === 'row') {
          return renderCell(
            value.orderStaus,
            value.chipBgColor,
            value.chipColor,
            ...args,
          );
        }
        return renderCellGroup(value, ...args);
      },
    },
    {
      title: 'Tracking #',
      field: 'trackingNumber',
      type: 'numeric',
      grouping: false,
      render: value => (
        <Link
          href="#"
          style={{ color: colors.blue[500] }}
          onClick={preventDefault}
        >
          {value.trackingNumber}
        </Link>
      ),
    },
  ] as Column<IOrderHistorySample>[];

  return (
    <MaterialTable
      title=""
      columns={columns}
      data={OrderHistorySample}
      style={theme.dtPaper}
      options={{
        headerStyle: theme.tableHeader as React.CSSProperties,
        padding: 'dense',
        pageSize: 10,
        exportButton: {
          csv: true,
          pdf: false,
        },
        exportAllData: true,
        exportFileName: 'ApprovalPlans',
        emptyRowsWhenPaging: false,
        grouping: true,
        rowStyle: {
          fontFamily: "'Lato', sans-serif",
          fontSize: 12,
        },
      }}
      localization={{
        grouping: {
          placeholder: 'Drag headers here to group by (Product and Status)',
        },
      }}
      components={{
        Toolbar: toolProps => (
          <div className={classes.toolBarDiv}>
            <AvatarIconTitle
              icon={faHistory}
              title="Hcp Consent Timeline"
              color={colors.indigo[900]}
              bgColor={colors.indigo[50]}
            />
            <div>
              <MTableToolbar {...toolProps} />
            </div>
          </div>
        ),
      }}
    />
  );
};

export default HcpConsentHistory;
