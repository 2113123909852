import * as React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { ConsentFormLoadable } from 'app/containers/ConsentForm/Loadable';
import { ConsentCaptureFormLoadable } from 'app/containers/ConsentCaptureForm/Loadable';
import { NotFoundPage } from 'app/containers/NotFoundPage/Loadable';
import { EmailVerificationLoadable } from 'app/containers/EmailVerification/Loadable';
import { VeevaEmailVerificationLoadable } from 'app/containers/VeevaEmailVerification/Loadable';
import { SubscriptionSuccessLoadable } from 'app/containers/SubscriptionSuccess/Loadable';
import {
  VEEVA_EMAIL_VERIFICATION_URL,
  CONSENT_FORM_URL,
  MAILER_SUCCESS_URL,
  SUCCESS_URL,
  LOGIN_URL,
  ACCESS_DENIED_URL,
  ADMIN_HOME,
  CONSENT_CAPTURE_FORM_URL,
} from 'utils/constants';
import { LoginLoadable } from 'app/containers/Login/Loadable';
import AccessDenied from 'app/components/AccessDenied';
import AdminHome from 'app/containers/AdminHome';
import { MailerSuccess } from '../../MailerSuccess';
import PrivateRoute from '../PrivateRoute';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={EmailVerificationLoadable}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${VEEVA_EMAIL_VERIFICATION_URL}`}
          component={VeevaEmailVerificationLoadable}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${CONSENT_FORM_URL}`}
          component={ConsentFormLoadable}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${CONSENT_CAPTURE_FORM_URL}`}
          component={ConsentCaptureFormLoadable}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${MAILER_SUCCESS_URL}`}
          component={MailerSuccess}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${SUCCESS_URL}`}
          component={SubscriptionSuccessLoadable}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${LOGIN_URL}`}
          component={LoginLoadable}
        />
        <PrivateRoute
          exact
          path={`${process.env.PUBLIC_URL}/${ADMIN_HOME}`}
          component={AdminHome}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${ACCESS_DENIED_URL}`}
          component={AccessDenied}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
