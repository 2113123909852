import { colors } from '@material-ui/core';
import { random } from 'lodash';
import { IOrderHistorySample } from './types';

export const Menus = {
  DashboardMenu: 0,
  UserManagement: 1,
  TableSchema: 2,
};

export const OrderHistorySample = [
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Conexacyn',
    orderPlaced: '02/14/2021',
    orderDelivered: '',
    orderStaus: 'In Transist',
    chipColor: colors.indigo[700],
    chipBgColor: colors.indigo[50],
    trackingNumber: String(random(10000, 99999999)),
  },
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Conexanol',
    orderPlaced: '02/12/2021',
    orderDelivered: '',
    orderStaus: 'In Process',
    chipColor: colors.blue[700],
    chipBgColor: colors.blue[50],
    trackingNumber: String(random(10000, 99999999)),
  },
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Convicyn',
    orderPlaced: '02/12/2021',
    orderDelivered: '02/16/2021',
    orderStaus: 'Failed',
    chipColor: colors.pink[700],
    chipBgColor: colors.pink[50],
    trackingNumber: String(random(10000, 99999999)),
  },
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Congra-D',
    orderPlaced: '02/12/2021',
    orderDelivered: '02/16/2021',
    orderStaus: 'Cancelled',
    chipColor: colors.amber[700],
    chipBgColor: colors.amber[50],
    trackingNumber: String(random(10000, 99999999)),
  },
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Congra-D',
    orderPlaced: '02/12/2021',
    orderDelivered: '02/16/2021',
    orderStaus: 'Delivered',
    chipColor: colors.green[700],
    chipBgColor: colors.green[50],
    trackingNumber: String(random(10000, 99999999)),
  },
  {
    orderNumber: String(random(10000, 50000)),
    product: 'Convicyn',
    orderPlaced: '02/12/2021',
    orderDelivered: '02/16/2021',
    orderStaus: 'Delivered',
    chipColor: colors.green[700],
    chipBgColor: colors.green[50],
    trackingNumber: String(random(10000, 99999999)),
  },
] as IOrderHistorySample[];
