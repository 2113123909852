import { createGlobalStyle } from 'styled-components';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #fafafa;
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    /* font-family: "'Lato', sans-serif"; */
  }

  body.fontLoaded {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    /* font-family: "'Lato', sans-serif"; */
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Montserrat, Times, 'Times New Roman', serif;
    /* font-family: "'Lato', sans-serif"; */
    line-height: 1.5em;
  }

.sk-spinner-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  /* background-color: #277eb8; */
  border-radius: 100%;
  -webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
          animation: sk-pulseScaleOut 1s infinite ease-in-out; 
  }
@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } 
}
@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } 
  }
`;
