import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faUserCog,
  faTable,
  faSignOut,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChartPie as faChartPieSolid,
  faUserCog as faUserCogSolid,
  faTable as faTableSolid,
} from '@fortawesome/pro-solid-svg-icons';

import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from 'app/components/Dialogs';
import UroGenLogo from 'images/UroGenLogo.png';

import { LOGIN_URL } from 'utils/constants';
import { Menus } from './constants';
import Account from './Components/UserManagement';
import Dashboard from './Components/Dashboard';
import PageUnderDevelopment from './Components/PageUnderDevelopment';
import PlaceOrder from './Components/PlaceOrder';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: '#FFFFFF',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2),

      padding: theme.spacing(0.5),
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    log: { padding: theme.spacing(1) },
    iconFont: { fontSize: '20px', color: theme.palette.primary.main },
    selected: {
      borderLeft: `5px solid ${theme.palette.primary.dark}`,
      backgroundColor: '#EFF8FF',
      borderRadius: 5,
    },
    unselect: {},
  }),
);

const { DashboardMenu, UserManagement, TableSchema } = Menus;

export default function AdminHome() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [selected, setSelected] = useState(DashboardMenu);
  const [showDialog, setShowDialog] = useState(false);
  const hanldeDialogClose = () => setShowDialog(false);
  const hanldeDialogShow = () => setShowDialog(true);

  const renderTab = () => {
    if (selected === UserManagement) {
      return <Account />;
    }
    if (selected === DashboardMenu) {
      return <Dashboard />;
    }
    if (selected === TableSchema) {
      return <PlaceOrder />;
    }
    return <PageUnderDevelopment />;
  };

  const handleConfirm = () => {
    hanldeDialogClose();
    window.localStorage.clear();
    history.push(LOGIN_URL);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Admin Portal</title>
        <meta name="description" content="Consent HUB Admin Portal" />
      </Helmet>
      <ConfirmationDialog
        isVisible={showDialog}
        title="Message!"
        handleClose={hanldeDialogClose}
        description="Are you sure you want to log out?"
        confirmText="Yes"
        cancelText="No"
        handleConfirm={handleConfirm}
      />
      <CssBaseline />
      <AppBar position="fixed" variant="outlined" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="primary" className={classes.title}>
            <img
              src={UroGenLogo}
              alt="UroGenLogo.png"
              style={{ height: 50, width: 195 }}
            />
          </Typography>
          <Typography
            variant="h5"
            noWrap
            style={{ color: theme.palette.primary.main }}
          >
            Admin Portal
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem
              button
              className={
                selected === DashboardMenu ? classes.selected : classes.unselect
              }
              onClick={() => setSelected(DashboardMenu)}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={
                    selected === DashboardMenu ? faChartPieSolid : faChartPie
                  }
                  className={classes.iconFont}
                />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Dashboard</Typography>}
              />
            </ListItem>
            <ListItem
              button
              className={
                selected === UserManagement
                  ? classes.selected
                  : classes.unselect
              }
              onClick={() => setSelected(UserManagement)}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={
                    selected === UserManagement ? faUserCogSolid : faUserCog
                  }
                  className={classes.iconFont}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">User Management</Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              className={
                selected === TableSchema ? classes.selected : classes.unselect
              }
              onClick={() => setSelected(TableSchema)}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={selected === TableSchema ? faTableSolid : faTable}
                  className={classes.iconFont}
                />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Table Schema</Typography>}
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={hanldeDialogShow}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faSignOut}
                  className={classes.iconFont}
                />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Log out</Typography>}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {renderTab()}
      </main>
    </div>
  );
}
