import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { LOGIN_URL } from 'utils/constants';
import unauthorised from 'images/unauthorised.jpg';
import check from 'images/check.png';
import cross from 'images/cross.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTran: {
      textTransform: 'none',
    },
    buttonDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    titleDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: '2em',
    },
    addSpace: {
      padding: theme.spacing(2),
    },
    parentDiv: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    rowFlex: {
      display: 'flex',
      flexDirection: 'row',
    },
    root: {
      justifyContent: 'left',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      height: '100%',
      backgroundImage: `linear-gradient(90deg, #21D4FD 0%, #B721FF 100%)`,
    },
    media: {
      borderRadius: 20,
    },
    padding: {
      padding: theme.spacing(1),
    },
    title: {
      // color: theme.palette.primary.main,
      textAlign: 'center',
    },
    smallLogoCss: { height: 25, width: 25 },
  }),
);

function AccessDenied({ history }) {
  const classes = useStyles();

  const hanldeSign = () => {
    history.push(LOGIN_URL);
  };

  return (
    <div className={classes.parentDiv}>
      <Card className={classes.root} elevation={0}>
        <CardHeader
          title={
            <div style={{ width: '100%' }}>
              <Typography variant="h5" className={classes.title}>
                Unauthorised!
              </Typography>
            </div>
          }
          subheader={
            <div className={classes.rowFlex}>
              <div>
                <Typography variant="body2">
                  Looks, like you have not signed into the system. Please use
                  the below Login button to login into the system.
                </Typography>
              </div>
            </div>
          }
        />
        <img
          src={unauthorised}
          alt="unauthorised"
          width={'50%'}
          height={'70%'}
          className={classes.media}
        />
        <img src={cross} alt="cross" width={'2%'} height={'10%'} />
        <img src={check} alt="check" width={'2%'} height={'10%'} />
      </Card>
      <div className={classes.buttonDiv}>
        <div className={classes.addSpace}>
          <Button
            variant="contained"
            className={classes.textTran}
            color="primary"
            onClick={hanldeSign}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccessDenied);
