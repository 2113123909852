import React from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer } from './slice';
import { rootSaga } from './saga';
import Routes from './Routes';

const Root = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: rootSaga });
  return <Routes />;
};

export default Root;
