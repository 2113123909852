/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
// import { Helmet } from 'react-helmet-async';
// import { useTranslation } from 'react-i18next';
import { GlobalStyle } from '../styles/global-styles';
// import config from './config';
import Root from './containers/Root';

export function App() {
  // eslint-disable-next-line
  return (
    <React.Fragment>
      <Root />
      <GlobalStyle />
    </React.Fragment>
  );
}
