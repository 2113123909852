import React, { useEffect } from 'react';
import disableBrowserBackButton from 'disable-browser-back-navigation';

import Lottie from 'react-lottie';
import { colors, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import * as QueryString from 'query-string';
import animationData from '../../lotties/emailsent.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.radius,
    },
    textTran: {
      textTransform: 'none',
    },
    buttonDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(2),
    },
    titleDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: '2em',
    },
    addSpace: {
      padding: theme.spacing(2),
    },
    parentDiv: {
      background: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    messageDiv: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      textAlign: 'center',
    },
  }),
);

export function MailerSuccess({ location }) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { email } = QueryString.parse(location.search);
  const classes = useStyles();
  useEffect(() => {
    disableBrowserBackButton();
  }, []);
  return (
    <div className={classes.parentDiv}>
      <div className={classes.titleDiv}>
        <div className={classes.messageDiv}>
          <Typography variant="h5">
            <FontAwesomeIcon icon={faCheckCircle} color={colors.green.A700} />
            &#8287;A verification link has been sent to&#8287;
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h5" style={{ color: colors.blue[700] }}>
            {email}
          </Typography>
        </div>
        <div style={{ padding: 10 }}>
          <Typography variant="body2">
            {`
            Please click on the link that has been sent to your email account to verify
            your email and complete the preference setup process.
            `}
          </Typography>
        </div>
      </div>
      <div>
        <Lottie
          options={defaultOptions}
          width={400}
          height={400}
          style={{ background: 'transparent' }}
        />
      </div>
    </div>
  );
}
