import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#2e3742';
export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: 'rgba(0, 116, 190, 1)',
    main: 'rgba(0, 116, 190, 1)',
    light: 'rgba(0, 174, 235, 1)',
  },
  secondary: {
    contrastText: white,
    dark: 'rgba(0, 116, 190, 1)',
    main: 'rgba(0, 174, 235, 1)',
    light: 'rgba(0, 174, 235, 1)',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    link: '#00AEEB',
    primary: '#54565B',
    secondary: '#54565B',
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: '#54565B',
  divider: colors.grey[200],
  cardTitle: '#54565B',
  chartTitle: '#54565B',
};
