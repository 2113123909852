// import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
// import { request } from 'utils/request';
// import { Repo } from 'types/Repo';

// import { actions } from './slice';

/**
 * Github repos request/response handler
 */
// export function* getAccessToken() {
//   yield delay(500);
//   // Select username from store

//   const requestURL = `https://api.github.com/users/${username}/repos?type=all&sort=updated`;

//   try {
//     // Call our request helper (see 'utils/request')
//     const repos: Repo[] = yield call(request, requestURL);
//     if (repos?.length > 0) {
//       yield put(actions.reposLoaded(repos));
//     } else {
//       yield put(actions.repoError(RepoErrorType.USER_HAS_NO_REPO));
//     }
//   } catch (err) {
//     if (err.response?.status === 404) {
//       yield put(actions.repoError(RepoErrorType.USER_NOT_FOUND));
//     } else if (err.message === 'Failed to fetch') {
//       yield put(actions.repoError(RepoErrorType.GITHUB_RATE_LIMIT));
//     } else {
//       yield put(actions.repoError(RepoErrorType.RESPONSE_ERROR));
//     }
//   }
// }

export function* rootSaga() {
  // yield takeLatest(actions.loadRootAccessToken.type, getRepos);
}
