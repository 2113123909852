import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { SUCCESS } from 'utils/constants';

import {
  ContainerState,
  ISncakbarMessage,
  ITokenResponse,
  IUser,
} from './types';

export const initialState: ContainerState = {
  loading: false,
  error: false,
  snackBarVisible: false,
  snackBarMessage: {
    type: SUCCESS,
    message: '',
  },
  user: {} as IUser,
  token: '',
};

const rootSlice = createSlice({
  name: 'rootState',
  initialState,
  reducers: {
    setRootLoader(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSnackbarMessage(state, action: PayloadAction<ISncakbarMessage>) {
      state.snackBarMessage = action.payload;
    },
    setSnackbarVisible(state, action: PayloadAction<boolean>) {
      state.snackBarVisible = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    loadRootAccessToken(state) {
      state.loading = true;
      state.error = false;
      state.user = {} as IUser;
      state.token = '';
    },
    setAccessToken(state, action: PayloadAction<ITokenResponse>) {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token.accessToken;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = rootSlice;
