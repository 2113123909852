import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
// import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  // typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  radius: 10,
  typography: {
    h5: {
      fontFamily: "'Lato'",
      color: palette.text.primary,
    },
    h6: {
      fontFamily: "'Lato'",
      color: palette.text.primary,
    },
    body1: {
      fontFamily: "'Lato'",
      color: palette.text.primary,
      letterSpacing: '-0.06px',
      lineHeight: '18px',
    },
    body2: {
      fontFamily: "'Montserrat'",
      color: palette.text.primary,
      letterSpacing: '-0.06px',
      lineHeight: '18px',
      fontWeight: 'bold',
    },
    fontSize: 14,
    // font: '#4a494b',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  tableHeader: {
    fontWeight: 'bold',
    color: palette.primary.main,
    borderTop: `1px solid ${palette.primary.main}`,
    borderBottom: `1px solid ${palette.primary.main}`,
    fontSize: '12px',
    wordWrap: 'normal',
    width: 'auto',
  },
  dtPaper: {
    borderRadius: 10,
    fontFamily: "'Lato', sans-serif",
  },
  size: {
    avatarIcon: 'sm',
    emptyChartIcon: '2x',
    emptyChartFPIcon: '3x',
    buttonIcon: '15px',
    chartHeight: '280',
  },
});

export default theme;
